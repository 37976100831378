export default {
  name: "global-footer",
  data() {
    return {
      document: null,
    };
  },
  methods: {
    getContent() {
      //Query to get footer content
      this.$prismic.client.getSingle("footer").then((document) => {
        if (document) {
          this.document = document;
        }
      });
    },
  },
  created() {
    this.getContent();
  }
};